import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../assets/css/style.css";
import "../assets/js/main";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { TelephoneFill } from "react-bootstrap-icons";

function About() {
  return (
    <section id="about" className="testimonials section-bg">
      <div className="site-section">
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-7 text-center">
              <div className="heading">
                <h2 className="text-black">Why choose us</h2>
              </div>
              <p className="lead text-dark">
                At My Passion for Seniors, we pride ourselves on providing the
                utmost professional, non-medical and passionate home care to
                the elderly and clients transitioning back home, assisted living,
                independent living, memory care, hospital and rehabilitation
                facility.
              </p>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-7">
              <div className="section-heading">
                {/* <h2 className="heading mb-3">Why choose us?</h2> */}
                <p className="mb-5 lead text-dark">
                  <ul>
                    <li>
                      We are a professional, dependable provider of services
                      tailored to meet individual needs with caregivers who are
                      not just employees; they are compassionate individuals who
                      have a genuine passion for working with seniors providing
                      coordinated care and support for our clients and their
                      families. .
                    </li>
                    <li>
                      We provide a level of service that meets the healthcare
                      industry's highest standards.
                    </li>
                    <li>
                      We provide 24-hour service, including all holidays and
                      weekends with flexible scheduling options and care plans
                      to accommodate evolving needs and preferences.
                    </li>
                    <li>
                      We provide a cost effective solution for patients in the
                      hospital or at home who need companion services.
                    </li>
                    <li>
                      We have trained, screened and credentialed home care
                      professionals who treat our clients like family. We
                      prioritize open communication, trust, and respect,
                      fostering a warm and supportive environment where seniors
                      feel valued, heard, and cared for..
                    </li>
                    <li>
                      Our response time for providing care is quick, often
                      within hours from the time requested.
                    </li>
                    <li>
                      We match your loved one with a caregiver best suited to
                      his or her needs..
                    </li>
                    <li>
                      We have reqular assessments by RN, Nurse to ensure client
                      satisfaction so you can have peace of mind knowing that
                      your loved ones are in caring and capable hands in the
                      safety and comfort of your home.
                    </li>
                    {/* <li>
                  We offer flexible payment options, including online bill payments, credit card, private pay. 
                  </li>
                  <li>
                  Free consultation. 
                  </li> */}
                    {/* <li>
                  Passionate Caregivers: Our 
                  </li> */}
                    {/* <li>
                  Family-like Approach: At My Passion for
                  Seniors, we treat our clients like family. We prioritize open
                  communication, trust, and respect, fostering a warm and
                  supportive environment where seniors feel valued, heard, and
                  cared for. 
                  </li> */}
                    {/* <li>
                  Flexibility and Convenience: We understand that the
                  needs of seniors and their families may change over time.
                  That's why we offer flexible scheduling options and
                  care plans to accommodate evolving needs and
                  preferences. 
                  </li> */}
                    {/* <li>
                  Peace of Mind: With My Passion for Seniors, you
                  can have peace of mind knowing that your loved ones are in
                  caring and capable hands in the safety and comfort of your home. We provide ongoing supervision,
                  regular communication, and 24/7 support to ensure the safety,
                  comfort, and well-being of our clients
                  </li> */}
                  </ul>
                </p>

                {/* <div className="row">
                  <div className="col-lg-6">
                    <img
                      src={require("../assets/images/happy.jpg")}
                      alt="Image"
                      className="img-fluid shadow"
                    />
                  </div>
                  <div className="col-lg-6">
                    <ul className="list-unstyled ul-check secondary">
                      <li>Consectetur adipisicing elit</li>
                      <li>Voluptate delectus ipsa</li>
                      <li>Maiores quia aliquam</li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="d-block custom-media algin-items-stretch">
                <div
                  className="img-bg shadow"
                  style={{
                    backgroundImage:
                      "url(" + require("../assets/images/cta.jpg") + ")",
                    backgroundPosition: "top",
                    height: "400px",
                  }}
                ></div>
                <div className="text text-center text-white bg-secondary">
                  <h1>Why choose us?</h1>
                </div>
                <Link
                      to="tel:+4042541634"
                      className="btn btn-success mt-2 w-100"
                    >
                      <TelephoneFill size={24}/> Call Now
                    </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { About };
