import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";

import "../assets/css/style.css";
import "../assets/js/main";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { TelephoneFill } from "react-bootstrap-icons";

function Hero() {
  const [isMobile, setIsMobile] = useState(false);
  const getBackgroundSize = (width, height) => {
    if (width >= 1025 || (width >= 768 && width <= 1024 && width > height)) {
      console.log("desktop");
      setIsMobile(false);
      return "desktop";
    } else if (width >= 768 && width <= 1024 && height > width) {
      console.log("tablet");
      setIsMobile(true);
      return "tablet";
    } else if ((width >= 1 && width <= 480) || (width >= 481 && width <= 767)) {
      console.log("mobile");
      setIsMobile(true);
      return "mobile";
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // Perform actions on window resize
      getBackgroundSize(visualViewport.width, visualViewport.height);
      console.log(
        getBackgroundSize(visualViewport.width, visualViewport.height)
      );
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", handleResize);
    };
  });

  return (
    <>
      <section id="hero">
        {isMobile ? (
          <div
            className="slide-item overlay"
            style={{
              backgroundImage:
                "url(" + require("../assets/images/hero-mobile.jpg") + ")",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 align-self-center"></div>
                <div className="col-lg-6 align-self-end">
                  <h1 className="heading mb-3">No place like home</h1>
                  <p className="lead text-white mb-5"><strong>
                    Choosing a reliable Home Care Agency is a crucial step in
                    ensuring your family members will have the level of care
                    they deserve. The needs of your family are always in our
                    focus and everything we do is dedicated to your family’s
                    well-being. Together, we can create a care plan for your
                    loved ones so they can maintain their independence and be an
                    active part of your family</strong>
                  </p>
                  <p>
                    <Link
                      to="tel:+4042541634"
                      className="btn btn-success"
                    >
                      <TelephoneFill size={24}/> Get In Touch
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="slide-item overlay"
            style={{
              backgroundImage:
                "url(" + require("../assets/images/hero-desktop.jpg") + ")",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 align-self-center">
                  <h1 className="heading mb-3">No place like home</h1>
                  <p className="lead text-white mb-5"><strong>
                    Choosing a reliable Home Care Agency is a crucial step in
                    ensuring your family members will have the level of care
                    they deserve. The needs of your family are always in our
                    focus and everything we do is dedicated to your family’s
                    well-being. Together, we can create a care plan for your
                    loved ones so they can maintain their independence and be an
                    active part of your family</strong>
                  </p>
                  <p>
                    <Link
                      to="tel:+4042541634"
                      className="btn btn-success"
                      onClick={() => {
                        getBackgroundSize(
                          visualViewport.width,
                          visualViewport.height
                        );
                      }}
                    >
                      <TelephoneFill size={24}/> Get In Touch
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default Hero;
