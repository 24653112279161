import { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../assets/css/style.css";
import "../assets/js/main";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import elderly006 from "../assets/images/svg/svg/006-elderly-3.svg";
import elderly005 from "../assets/images/svg/svg/005-elderly-2.svg";
import elderly004 from "../assets/images/svg/svg/004-nurse.svg";
import {
  Mastercard,
  Visa,
  Paypal,
  Unionpay,
  Discover,
  Amex,
  Jcb,
  Diners,
  Maestro,
} from "react-payment-logos/dist/flat-rounded";
import { Envelope, Telephone, TelephoneFill, Whatsapp } from "react-bootstrap-icons";

function Cta() {
  const [isMobile, setIsMobile] = useState(false);

  const getBackgroundSize = (width, height) => {
    if (width >= 1025 || (width >= 768 && width <= 1024 && width > height)) {
      console.log("desktop");
      setIsMobile(false);
      return "desktop";
    } else if (width >= 768 && width <= 1024 && height > width) {
      console.log("tablet");
      setIsMobile(true);
      return "tablet";
    } else if ((width >= 1 && width <= 480) || (width >= 481 && width <= 767)) {
      console.log("mobile");
      setIsMobile(true);
      return "mobile";
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // Perform actions on window resize
      getBackgroundSize(visualViewport.width, visualViewport.height);
      console.log(
        getBackgroundSize(visualViewport.width, visualViewport.height)
      );
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", handleResize);
    };
  });
  return (
    <section id="cta" className="testimonials section-bg">
      <div
        className="cover overlay"
        style={{
          backgroundImage: isMobile
            ? "url(" + require("../assets/images/img_5.jpg") + ")"
            : "url(" + require("../assets/images/img_6.jpg") + ")",
        }}
      >
        <div className="container">
          <div className="row ">
            <div className="col-lg-7 mx-auto text-center align-self-center">
              <h1 className="mb-5 heading">
                Exceptional personal and companion care in the comfort of your
                home.
              </h1>
              <p className="display-6 text-white">
                Conatct us today and get free consultation
              </p>
              <div className="row">
                <div className="col-4">
                  <a
                    href="tel:+4042541634"
                    className="link-underline link-underline-opacity-0"
                  >
                    <div className="feature badge">
                      <span className="img-wrap">
                        <img
                          src={elderly006}
                          alt="elderly care"
                          className="img-fluid"
                        />
                      </span>
                      <h3><Telephone /> Call Us</h3>
                    </div>
                  </a>
                </div>
                <div className="col-4">
                  <Link
                    to="https://wa.me/+14049146754?text=I%20want%20to%20enquire%20about%20your%20services"
                    className="link-underline link-underline-opacity-0"
                  >
                    <div className="feature badge">
                      <span className="img-wrap">
                        <img
                          src={elderly005}
                          alt="elderly care"
                          className="img-fluid"
                        />
                      </span>
                      <h3><Whatsapp /> Message Us</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-4">
                  <a
                    href="mailto:info@mypassionforseniorsllc.com"
                    className="link-underline link-underline-opacity-0"
                  >
                    <div className="feature badge">
                      <span className="img-wrap">
                        <img
                          src={elderly004}
                          alt="elderly care"
                          className="img-fluid"
                        />
                      </span>
                      <h3><Envelope /> Email Us</h3>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <h3 className="text-white text-center fst-italic">
              Flexible payment options for our services, including private pay,
              online payment options and long term care
            </h3>
            <p className="d-flex justify-content-center">
              <Mastercard width={100} height={100} className="p-2 shadow" />
              <Amex width={100} height={100} className="p-2 shadow" />
              <Unionpay width={100} height={100} className="p-2 shadow" />
              <Visa width={100} height={100} className="p-2 shadow" />
              <Discover width={100} height={100} className="p-2 shadow" />
              <Jcb width={100} height={100} className="p-2 shadow" />
              <Maestro width={100} height={100} className="p-2 shadow" />
              <Diners width={100} height={100} className="p-2 shadow" />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
