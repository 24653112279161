import "bootstrap/dist/css/bootstrap.css";
import "../assets/css/style.css";
import "../assets/js/main";
import { useEffect, useState } from "react";
import "../assets/scss/style.scss";
import { logodark } from "../assets/images/mypassion4seniorsdark.png"
import {
  List,
  ChevronDown,
  X,
  Facebook,
  Twitter,
  Instagram,
  Linkedin,
  TwitterX,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

function Header() {
  const [ isScrolled, setIsScrolled ] = useState(false)
  useEffect(() => {
    // When the user scrolls the page, execute myFunction

    const header = document.querySelector("#header");

    if (header) {
      const headerScrolled = () => {
        
        header.classList.toggle("header-scrolled", window.scrollY > 100);
        if (window.scrollY > 100) { 
          setIsScrolled(true); 
         } else {setIsScrolled(false);}
      };

      window.addEventListener("load", headerScrolled);
      document.addEventListener("scroll", headerScrolled);
    }
  }, []);

  return (
    <header
      id="header"
      className="site-navbar light fixed-top d-flex"
      role="banner"
      style={{ position: "fixed", top: "0" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6 col-xl-2">
            <Link to="/"><div className="mb-2 mt-2 site-logo">
              {isScrolled ?
              <img
                src={require("../assets/images/mypassion4seniorsdark.png")}
                width={"300px"}
                alt="logo"
              />
              :
              <img
                src={require("../assets/images/mypassion4seniorswhite.png")}
                width={"300px"}
                alt="logo"
              />
            }
            </div></Link>
            {/* <small className="text-primary">License No: PHCP011875</small> */}
          </div>
            
          <div className="col-12 col-md-10 d-none d-xl-block">
            
            <nav
              id="navbar"
              className="site-navigation position-relative text-end"
              role="navigation"
            >
              <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                <li>
                  <HashLink
                    to="/#hero"
                    className={`${isScrolled ? 'active nav-link' : 'active nav-link text-warning'}`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Home
                  </HashLink>
                </li>
                <li className="has-children">
                  <a href="about" className={`${isScrolled ? 'nav-link' : 'nav-link text-warning'}`}>
                    Our Services <ChevronDown />
                  </a>
                  <ul className="dropdown">
                    <li>
                      <HashLink
                        to="/about#companionship"
                        className="nav-link"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        Companionship Care
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        to="/about#homemaking"
                        className="nav-link"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        Homemaking Care
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        to="/about#personal"
                        className="nav-link"
                        onClick={() => {
                          window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Peronal Care
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        to="/about#privateduty"
                        className="nav-link"
                        onClick={() => {
                          window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        24/7 Private Duty
                      </HashLink>
                    </li>
                    {/* <li>
                      <a href="#" className="nav-link">
                        Excellent Cuisine
                      </a>
                    </li> */}
                    {/* <li className="has-children">
                      <a href="#">More Links</a>
                      <ul className="dropdown">
                        <li>
                          <a href="#">Menu One</a>
                        </li>
                        <li>
                          <a href="#">Menu Two</a>
                        </li>
                        <li>
                          <a href="#">Menu Three</a>
                        </li>
                      </ul>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link
                    to="about"
                    className={`${isScrolled ? 'nav-link' : 'nav-link text-warning'}`}
                    onClick={() => {
                      window.scroll({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <HashLink to="/#testimonials" className={`${isScrolled ? 'nav-link' : 'nav-link text-warning'}`}>
                    Client Stories
                  </HashLink>
                </li>

                {/* <li>
                  <a href="blog.html" className="nav-link">
                    Blog
                  </a>
                </li> */}

                <li>
                  <HashLink to="/#contact" className={`${isScrolled ? 'nav-link' : 'nav-link text-warning'}`}>
                    Share Your Thoughts
                  </HashLink>
                </li>

                {/* <li className="social p-1">
                  <a href="#contact-section" className="nav-link">
                    <span className="icon-facebook">
                      <Facebook />
                    </span>
                  </a>
                </li>
                <li className="social p-1">
                  <a href="#contact-section" className="nav-link">
                    <span className="icon-twitter">
                      <TwitterX />
                    </span>
                  </a>
                </li>
                <li className="social p-1">
                  <a href="#contact-section" className="nav-link">
                    <span className="icon-instagram">
                      <Instagram />
                    </span>
                  </a>
                </li>
                <li className="social p-1">
                  <a href="#contact-section" className="nav-link">
                    <span className="icon-linkedin">
                      <Linkedin />
                    </span>
                  </a>
                </li> */}
                {/* <li>
                  <HashLink to="/#pay" className="btn btn-sm btn-warning ms-2">
                    Pay Online
                  </HashLink>
                </li> */}
              </ul>
            </nav>
          </div>

          <div
            className="col-6 d-inline-block d-xl-none ml-md-0 py-3"
            style={{ position: "relative", top: "3px" }}
          >
            <a href="#" className="site-menu-toggle js-menu-toggle float-end">
              <span className="icon-menu h3 text-white">
                <List
                  className="mobile-nav-toggle"
                  size={40}
                  id="navbar-toggle"
                  // ref={ref}
                  // onClick={()=>{setIsActive(!isActive);
                  //   document.querySelector('#navbar').classList.toggle('navbar-mobile')
                  //   console.log(isActive)}}
                />
              </span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export { Header };
